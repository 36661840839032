import { Grid } from '@material-ui/core';
import React from 'react';

import Awards from '../Awards';
import Layout from '../Layout';
import Narrow from '../Narrow';
import PageTitle from '../PageTitle';

export default ({ pageContext: { searchIndex, awards } }) => (
  <Layout background={true} searchIndex={searchIndex}>
    <PageTitle>{awards.title.rendered}</PageTitle>
    <Narrow>
      <Grid container justify="center" spacing={40}>
        <Grid item xs={12}>
          <Awards awards={awards.acf.awards} />
        </Grid>
      </Grid>
    </Narrow>
  </Layout>
);
