import React from 'react';
import styled from 'styled-components';

const Project = styled.h3`
  color: white;
  font-size: 1.2rem;
  margin-top: 3.5rem;
  margin-bottom: 0.5rem;
  font-family: ffvCorporateFat, sans-serif;
  text-align: center;
`;
const Description = styled.p`
  color: white;
  margin: 0.5rem 0;
  font-size: 1.2rem;
  font-family: ffvCorporateFat, sans-serif;
  text-align: center;
`;
const Prices = styled.div`
  color: white;
  font-size: 1.2rem;
  font-family: ffvGaramond, serif;
  text-align: center;
`;
const Award = ({ project, description, awards }) => (
  <>
    <Project>{project}</Project>
    <Description>{description}</Description>
    <Prices dangerouslySetInnerHTML={{ __html: awards }} />
  </>
);
export default ({ awards }) =>
  awards &&
  awards.map((award, i) => (
    <Award
      key={`${award.project}${i}`}
      project={award.project}
      description={award.description}
      awards={award.awards}
    />
  ));
